import React, { useEffect, useState, useRef } from "react";
import css from "./index.module.scss";
import { Card, DatePicker, Button, Image, Popover, message, Table, Select } from "antd";
import { InfoCircleOutlined, DownSquareOutlined, UpSquareOutlined,  PayCircleOutlined,QrcodeOutlined, RightCircleOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import { imgplaceholder, copyToClipboard } from "../../utils";
import { getEnterPrise, getStatistics, getStatisticsList } from "../../api";
import * as echarts from 'echarts';
import { useNavigate } from "react-router-dom";
import { orderTypes } from "../../utils";
import { NewTableTh } from "../component/NewTableTh";

const PopContent = React.memo(({ imgurl, url }) => {
    const copy = () => {
        copyToClipboard(url);
        message.success('复制成功');
    }

    return (
        <div className={css.cflex}>
            <Image
                width={120}
                height={160}
                src={imgurl}
                fallback={imgplaceholder}
            />
            <span style={{ color: '#1296db', margin: '15px 0', cursor: 'pointer' }} onClick={copy}>复制链接</span>
        </div>

    )
})

const options = [
    { value: 1, label: '近一周' },
    { value: 2, label: '近一月' },
    { value: 3, label: '近一年' },
]

const lineOptions = {
    xAxis: {
        type: 'category',
    },
    yAxis: {
        name: '金额（元）',
        type: 'value'
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'line'
        },
        show: 'true'
    },
    grid: {
        left: '10%',
        right: '10%',
        bottom: '10%',
        containLabel: false
    },
    valueFormatter: (value) => value.toFixed(2) + '元',
    series: [
        {
            type: 'line'
        }
    ]
};
const pieOptions={
    tooltip: {
      trigger: 'item',
    },
    valueFormatter: (value) => value + '%',
    legend: {
      orient: 'vertical',
      left: 'left',
      bottom:'bottom'
    },
    series: [
      {
        name: '支付方式',
        type: 'pie',
        radius: '60%',
        data: [
          { value: 50, name: '建行支付' },
          { value: 40, name: '微信支付' },
          { value: 10, name: '其他' }
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
};
const barOptions={
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
        left: 'left',
        bottom:'bottom'
    },
    grid: {
      left: '3%',
      right: '4%',
      top: '10%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01]
    },
    yAxis: {
      type: 'category',
      data: [ '合唱班', '草莓','薯片（原味）', '甜筒', '薯片（红烩味）']
    },
    series: [
      {
        name: '销量',
        type: 'bar',
        data: [9,12,20,49,100]
      }
    ]
  };

function Index() {
    const [curdate, setDate] = useState(dayjs(new Date()));
    const [list, setList] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [topdata, setTopData] = useState({});
    const [timeMode, setTimeMode] = useState(1);

    const [myChart, setMyChart] = useState(null);
    const [myChart2, setMyChart2] = useState(null);
    const [myChart3, setMyChart3] = useState(null);

    const [amount, setAmount] = useState({ date: [], value: [] });

    const chart = useRef(null);
    const chart2 = useRef(null);
    const chart3 = useRef(null);

    const card2=useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        setMyChart(echarts.init(chart.current));
        setMyChart2(echarts.init(chart2.current));
        setMyChart3(echarts.init(chart3.current));
        getList();
    }, [])
    useEffect(() => {
        getTopData(curdate);
    }, [curdate])
    useEffect(() => {
        getAmountList();
    }, [timeMode])
    useEffect(() => {
        myChart2 && myChart2.setOption(pieOptions);
        myChart3 && myChart3.setOption(barOptions);
        myChart2 && myChart2.resize();
        myChart3 && myChart3.resize();
    }, [myChart2,myChart3])
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [myChart, myChart2, myChart3])
    useEffect(() => {
        lineOptions.xAxis.data = amount.date;
        lineOptions.series[0].data = amount.value;
        myChart && myChart.setOption(lineOptions);
        myChart && myChart.resize();
    }, [amount])

    const getList = async () => {
        const req = {
            size: 5,
            current: 1,
            status: 1
        };
        setTableLoading(true);
        const data = await getEnterPrise(req);
        if (data.code === 200) {
            setList(data.data.records);
        } else {
            message.error({ content: data.message, duration: 2 });
        }
        setTableLoading(false);
    };

    const columns = [
        {
            title: "名称",
            dataIndex: "name",
            width: 100
        },
        {
            title: '项目类型',
            dataIndex: 'projectType',
            width: 100,
            render: (text) => {
                const item = orderTypes.find((item) => item.value === +text);
                return item ? item.label : '';
            }
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            render: (text) => {
                return text ? text.replace(/T/g, " ") : '';
            },
            width: 200
        },
        {
            title: "码牌",
            dataIndex: "status",
            fixed: "right",
            width: 100,
            render: (text, record) => {
                switch (+text) {
                    case 1:
                        return <Popover zIndex={10} content={<PopContent url={record.projectUrl} imgurl={record.projectPicUrl} />} title="发布结果" trigger="click"><QrcodeOutlined/></Popover>;
                    default:
                        return "";
                }
            }
        }
    ];
    const getTopData = async (date) => {
        const data = await getStatistics({ date: dayjs(date).format('YYYY-MM-DD') });
        if (data.code === 200) {
            setTopData(data.data);
        } else {
            message.error({ content: data.message, duration: 2 });
        }
    }
    const changeCurDate = (date) => {
        setDate(date);
    }
    const changeTimeMode = (value) => {
        setTimeMode(value);
    }
    const goProjectList = () => {
        navigate('/home/deco/manage');
    }
    const goProductList = () => {
        navigate('/home/product/list');
    }
    const goCreate = () => {
        navigate('/home/deco/manage', { state: { tab: 1 } });
    }
    const getAmountList = async () => {
        const now = new Date();
        let day = timeMode === 1 ? 7 : timeMode === 2 ? 30 : 365;
        let startTime = dayjs(now.getTime() - 1000 * 60 * 60 * 24 * day).format('YYYY-MM-DD');
        const data = await getStatisticsList({ endTime: dayjs(now).format('YYYY-MM-DD'), startTime });
        if (data.code === 200) {
            const date = [], value = [];
            data.data.forEach(item => {
                date.push(item.dateTime);
                value.push(item.todayAmount);
            })
            setAmount({ date, value });
        } else {
            message.error({ content: data.message, duration: 2 });
        }
    }
    function throttle(func, delay) {
        let lastCalledTime = 0;
        return function (...args) {
            const now = Date.now();
            if (now - lastCalledTime >= delay) {
                func.apply(this, args);
                lastCalledTime = now;
            }
        };
    }

    const handleResize = throttle(() => {
        myChart.resize();
        myChart2.resize();
        myChart3.resize();
    }, 200);

    return (
        <div className={css.container}>
            <div className={css.rowflex}>
                <Card className={css.card1}>
                    <div className={css.contain1}>
                        <div className={css.left}>
                            <DatePicker value={curdate} format='YYYY-MM-DD' style={{ width: '150px' }} onChange={changeCurDate} allowClear={false} />
                            <div className={css.tcontain}>
                                <div className={css.t1}>交易金额<Popover content='入账资金，退款金额未纳入'><InfoCircleOutlined style={{ marginLeft: '10px' }} /></Popover></div>
                                <div className={css.t2}>¥{topdata.todayAmount ?? 0}</div>
                            </div>
                        </div>
                        <div className={css.right}>
                            <div className={css.item} style={{ background: '#72DFFC' }}>
                                <div className={css.top}>
                                    <div className={css.icon}>
                                        <DownSquareOutlined style={{ color: '#72DFFC'}} />
                                    </div>
                                    <div className={css.label}>交易笔数</div>
                                </div>
                                <div className={css.value}>{topdata.todayCount ?? 0}</div>
                            </div>
                            <div className={css.item} style={{ background: '#7287FC' }}>
                                <div className={css.top}>
                                    <div className={css.icon}>
                                        <UpSquareOutlined style={{ color: '#7287FC' }} />
                                    </div>
                                    <div className={css.label}>退款笔数</div>
                                </div>
                                <div className={css.value}>{topdata.refundCount ?? 0}</div>
                            </div>
                            <div className={css.item} style={{ background: '#72B3FC' }}>
                                <div className={css.top}>
                                    <div className={css.icon}>
                                        <PayCircleOutlined style={{ color: '#72B3FC' }} />
                                    </div>
                                    <div className={css.label}>退款金额</div>
                                </div>
                                <div className={css.value}>¥{topdata.refundAmount}</div>
                            </div>
                        </div>
                    </div>
                </Card>
                <Card className={css.card3} title="交易金额趋势" extra={<Select options={options} value={timeMode} onChange={changeTimeMode}></Select>}>
                    <div ref={chart} style={{ width: '100%', height: '250px' }}></div>
                </Card>
            </div>

            <div className={css.rowflex} style={{marginTop:'20px'}}>
                <Card className={css.card2} title="进行中的项目" extra={<Button type="link" onClick={goProjectList}>更多</Button>} ref={card2}>
                    <Table
                        components={NewTableTh}
                        loading={tableLoading}
                        columns={columns}
                        dataSource={list}
                        pagination={false}
                        rowKey="id"
                    />
                </Card>
                <div className={css.create}>
                    <div className={css.rightbox}>
                        <div className={css.h1}>立刻创建</div>
                        <div className={css.h2}>定制你的专属收款页面</div>
                        <div className={css.plus} onClick={goCreate}>开始使用</div>
                        <div className={css.bg}></div>
                    </div>
                </div>
            </div>

            <div className={css.rowflex} style={{marginTop:'20px'}}>
                <Card className={css.card4} title="商品销量Top5" extra={<Button type="link" onClick={goProductList}>更多商品</Button>}>
                    <div ref={chart3} style={{ width: '100%', height: '250px' }}></div>
                </Card>
                <Card className={css.card5} title="支付方式占比">
                    <div ref={chart2} style={{ width: '100%', height: '250px' }}></div>
                </Card>
            </div>
        </div>
    );
}

export default React.memo(Index);