import fetch from "../utils/fetch";

/********************登录相关***************************/
//登录
export function Login(data) {
  return fetch({
    url: "/adminapi/login",
    method: "post",
    params: data,
  });
}
//登录信息
export function getUserInfo() {
  return fetch({
    url: "/adminapi/sys/menu/userMenuInfo",
    method: "get",
  });
}
//验证码
export function getValidateCode(data) {
  return fetch({
    url: "/adminapi/validatecode/randomcodes",
    method: "get",
    params: data,
    responseType: "blob",
  });
}
/**********************************首页相关*****************************************/
//获取统计信息
export function getStatistics(data) {
  return fetch({
    url: "/adminapi/sms/order/orderStatistics",
    method: "get",
    params: data,
  });
}
//获取折线图数据
export function getStatisticsList(data) {
  return fetch({
    url: "/adminapi/sms/order/orderStatisticsList",
    method: "get",
    params: data,
  });
}
/**********************************系统参数管理相关*****************************************/
/*************************用户信息维护**************************/
//获取用户信息列表
export function getUserList(data) {
  return fetch({
    url: "/adminapi/sys/user/pages",
    method: "get",
    params: data,
  });
}
//创建用户
export function addUser(data) {
  return fetch({
    url: "/adminapi/sys/user/save",
    method: "post",
    data: data,
  });
}
//修改用户
export function updateUser(data) {
  return fetch({
    url: "/adminapi/sys/user/update",
    method: "put",
    data: data,
  });
}
//修改密码
export function changePassword(data) {
  return fetch({
    url: "/adminapi/sys/user/resetPassword",
    method: "post",
    params: data,
  });
}
//修改用户信息
export function updateUserInfo(data) {
  return fetch({
    url: "/adminapi/sys/user/updateUser",
    method: "put",
    data: data,
  });
}
//删除用户
export function deleteUser(data) {
  return fetch({
    url: "/adminapi/sys/user/delete",
    method: "delete",
    params: data,
  });
}
//获取角色分配列表
export function getRolePerms(data) {
  return fetch({
    url: "/adminapi/sys/role/roles",
    method: "get",
    params: data,
  });
}
//修改角色分配列表
export function updateRolePerms(data) {
  return fetch({
    url: "/adminapi/user/updateUserRolesList",
    method: "get",
    params: data,
  });
}
/*************************角色维护**************************/
//获取角色列表
export function getRoleList(data) {
  return fetch({
    url: "/adminapi/sys/role/pages",
    method: "get",
    params: data,
  });
}
//创建角色
export function addRole(data) {
  return fetch({
    url: "/adminapi/sys/role/save",
    method: "post",
    data: data,
  });
}
//修改角色
export function updateRole(data) {
  return fetch({
    url: "/adminapi/sys/role/update",
    method: "put",
    data: data,
  });
}
//删除角色
export function deleteRole(data) {
  return fetch({
    url: "/adminapi/sys/role/delete",
    method: "delete",
    params: data,
  });
}
//获取所有菜单列表
export function getPerms(data) {
  return fetch({
    url: "/adminapi/sys/menu/list",
    method: "get",
    params: data,
  });
}
/*************************菜单设置*************************/
//获取菜单分页
export function getMenuList(data) {
  return fetch({
    url: "/adminapi/sys/menu/list",
    method: "get",
    params: data,
  });
}
//创建菜单
export function addMenu(data) {
  return fetch({
    url: "/adminapi/sys/menu/save",
    method: "post",
    data: data,
  });
}
//修改菜单
export function updateMenu(data) {
  return fetch({
    url: "/adminapi/sys/menu/update",
    method: "put",
    data: data,
  });
}
//删除菜单
export function deleteMenu(data) {
  return fetch({
    url: "/adminapi/sys/menu/delete",
    method: "delete",
    params: data,
  });
}
/*************************商户设置*************************/
//获取商户分页
export function getMerchant(data) {
  return fetch({
    url: "/adminapi/sms/merchant/pages",
    method: "get",
    params: data,
  });
}
//新增商户
export function addMerchant(data) {
  return fetch({
    url: "/adminapi/sms/merchant/save",
    method: "post",
    data: data,
  });
}
//更新商户
export function updateMerchant(data) {
  return fetch({
    url: "/adminapi/sms/merchant/update",
    method: "put",
    data: data,
  });
}
//删除商户
export function deleteMerchant(data) {
  return fetch({
    url: "/adminapi/sms/merchant/delete",
    method: "delete",
    params: data,
  });
}

/****************************************************************/
//上传文件
export function uploadFile(data) {
  return fetch({
    url: "/adminapi/file/upload",
    method: "post",
    data: data,
  });
}

/*************************表单设置*************************/
//获取模板分页
export function getEnterPrise(data) {
  return fetch({
    url: "/adminapi/sys/project/list",
    method: "get",
    params: data,
  });
}
//创建模板
export function addEnterPrise(data) {
  return fetch({
    url: "/adminapi/sys/project/create",
    method: "post",
    data: data,
  });
}
//修改模板
export function updateEnterPrise(data) {
  return fetch({
    url: "/adminapi/sys/project/update",
    method: "put",
    data: data,
  });
}
//删除模板
export function deleteEnterPrise(data) {
  console.log(data);
  return fetch({
    url: "/adminapi/sys/project/delete",
    method: "delete",
    params: data,
  });
}
//获取单个模板详情
export function getEnterPriseDetail(data) {
  return fetch({
    url: "/adminapi/sys/project/projectInfo",
    method: "get",
    params: data,
  });
}
//获取地址列表
export function getAddressList(data) {
  return fetch({
    url: "/adminapi/sys/address/list",
    method: "get",
    params: data,
  });
}
//绑定人群
export function bindPeople(data) {
  return fetch({
    url: "/adminapi/sms/smsGroupUser/associateTemplat2",
    method: "put",
    data: data,
  });
}
//复制项目
export function copyProject(data) {
  return fetch({
    url: "/adminapi/sys/project/copy",
    method: "get",
    params: data,
  });
}
//未支付人群
export function getNotPay(data) {
  return fetch({
    url: "/adminapi/sys/project/notPay",
    method: "get",
    params: data,
  });
}
/*************************表单设置*************************/
//获取模板分页
export function getTemplat(data) {
  return fetch({
    url: "/adminapi/templat/list",
    method: "get",
    params: data,
  });
}
//创建模板
export function addTemplat(data) {
  return fetch({
    url: "/adminapi/templat/add",
    method: "post",
    data: data,
  });
}
//修改模板
export function updateTemplat(data) {
  return fetch({
    url: "/adminapi/templat/update",
    method: "put",
    data: data,
  });
}
//删除模板
export function deleteTemplat(data) {
  console.log(data);
  return fetch({
    url: "/adminapi/templat/delete",
    method: "delete",
    params: data,
  });
}

/*************************---------------------人群设置--------------------*************************/
/*************************单个用户设置*************************/
//获取单个用户分页
export function getPeople(data) {
  return fetch({
    url: "/adminapi/customtemplat/smsSingleUser/complex/pages",
    method: "get",
    params: data,
  });
}
//新增用户
export function addPeople(data) {
  return fetch({
    url: "/adminapi/customtemplat/smsSingleUser/save",
    method: "post",
    data: data,
  });
}
//更新用户
export function updatePeople(data) {
  return fetch({
    url: "/adminapi/customtemplat/smsSingleUser/update",
    method: "put",
    data: data,
  });
}
//删除用户
export function deletePeople(data) {
  return fetch({
    url: "/adminapi/customtemplat/smsSingleUser/delete",
    method: "delete",
    params: data,
  });
}
//获取人群包列表
export function getGrouplists(data) {
  return fetch({
    url: "/adminapi/sms/smsGroupUser/list",
    method: "get",
    params: data,
  });
}
//批量绑定人群包
export function bindGroup(data) {
  return fetch({
    url: "/adminapi/customtemplat/smsSingleUser/saveTagList",
    method: "post",
    data: data,
  });
}
//批量导入用户
export function importUser(data) {
  return fetch({
    url: "/adminapi/customtemplat/smsSingleUser/importSingleUserFile",
    method: "post",
    data: data,
  });
}
//导出模板
export function exportDeco() {
  return fetch({
    url: "/adminapi/peopleModel.xls",
    method: "get",
    responseType: "arraybuffer",
  });
}

/*************************人群包设置*************************/
//获取人群包
export function getGroups(data) {
  return fetch({
    url: "/adminapi/sms/smsGroupUser/pages",
    method: "get",
    params: data,
  });
}
//新增人群包
export function addGroups(data) {
  return fetch({
    url: "/adminapi/sms/smsGroupUser/save",
    method: "post",
    data: data,
  });
}
//更新人群包
export function updateGroups(data) {
  return fetch({
    url: "/adminapi/sms/smsGroupUser/update",
    method: "put",
    data: data,
  });
}
//删除人群包
export function deleteGroups(data) {
  return fetch({
    url: "/adminapi/sms/smsGroupUser/delete",
    method: "delete",
    params: data,
  });
}

/*************************---------------------账单中心--------------------*************************/
/*************************账单管理*************************/
//获取账单分页
export function getBillList(data) {
  return fetch({
    url: "/adminapi/sms/order/queryOrderPage",
    method: "post",
    data: data,
  });
}
//导出账单
export function exportBillList(data) {
  return fetch({
    url: "/adminapi/sms/order/exportOrderList",
    method: "post",
    data: data,
    responseType: "arraybuffer",
  });
}
//退款
export function agreeRefund(data) {
  return fetch({
    url: "/adminapi/sms/refund/agree",
    method: "post",
    params: data,
  });
}
//拒绝退款
export function refuseRefund(data) {
  return fetch({
    url: "/adminapi/sms/refund/reject",
    method: "post",
    params: data,
  });
}
/*************************---------------------商品中心--------------------*************************/
/*************************商品管理*************************/
//获取商品分页
export function getProductList(data) {
  return fetch({
    url: "/adminapi/api/product/list",
    method: "get",
    params: data,
  });
}
//新增商品
export function addProduct(data) {
  return fetch({
    url: "/adminapi/api/product/save",
    method: "post",
    data: data,
  });
}
//更新商品
export function updateProduct(data) {
  return fetch({
    url: "/adminapi/api/product/update",
    method: "put",
    data: data,
  });
}
//删除商品
export function deleteProduct(data) {
  return fetch({
    url: "/adminapi/api/product/delete",
    method: "delete",
    params: data,
  });
}
/*************************商品管理*************************/
//获取商品分类
export function getCategoryList(data) {
  return fetch({
    url: "/adminapi/api/category/list",
    method: "get",
    params: data,
  });
}
//新增商品分类
export function addCategory(data) {
  return fetch({
    url: "/adminapi/api/category/save",
    method: "post",
    data: data,
  });
}
//更新商品分类
export function updateCategory(data) {
  return fetch({
    url: "/adminapi/api/category/update",
    method: "put",
    data: data,
  });
}
//删除商品分类
export function deleteCategory(data) {
  return fetch({
    url: "/adminapi/api/category/delete",
    method: "delete",
    params: data,
  });
}

/*************************物流管理*************************/
//下载模板
export function exportLogistic(data) {
  return fetch({
    url: "/adminapi/orderModel.xlsx",
    method: "get",
    responseType: "arraybuffer",
  });
}
//
export function importLogistic(data) {
  return fetch({
    url: "/adminapi/sms/order/uploadFile",
    method: "post",
    data: data,
  });
}
//修改物流单号
export function changeDeliverySn(data) {
  return fetch({
    url: "/adminapi/sms/order/modifyDeliverySn",
    method: "put",
    data: data,
  });
}

/*************************智能图库*************************/
//辅助输入
export function imgHelpSearch(data) {
  return fetch({
    url: "/adminapi/sms/img/key/list",
    method: "get",
    params: data,
  });
}
//修改图片
export function imgUpdate(data) {
  return fetch({
    url: "/adminapi/sms/img/update",
    method: "post",
    data: data,
  });
}
//保存图片
export function imgSave(data) {
  return fetch({
    url: "/adminapi/sms/img/save",
    method: "post",
    data: data,
  });
}
//图片列表
export function imgList(data) {
  return fetch({
    url: "/adminapi/sms/img/pages",
    method: "post",
    data: data,
  });
}
//删除图片
export function imgDelete(data) {
  return fetch({
    url: "/adminapi/sms/img/delete",
    method: "delete",
    params: data,
  });
}