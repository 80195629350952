import React from 'react'
import { Form, Input, Button, message } from 'antd'
import { updateUserInfo } from '../../api';
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../../store/features/userInfoSlice";
import './index.scss'

function UserInfoModal(props) {
    const [modalform] = Form.useForm();
    const user = useSelector(state => state.userInfo.user);
    const dispatch = useDispatch();

    React.useEffect(() => {
        modalform.setFieldsValue({
            phonenumber: user.phonenumber,
            email: user.email
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    async function submit(val) {
        const data = {
            phonenumber: val.phonenumber,
            email: val.email,
        }
        const result = await updateUserInfo(data);
        if (result.code === 200) {
            message.success({ content: result.message??'修改成功', duration: 2 });
            dispatch(updateUser(data));
            props.close();
        } else {
            message.error({ content: result.message??'修改失败', duration: 2 });
        }
    }

    return (
        <Form layout="vertical" form={modalform} name="modalform" onFinish={submit} onFinishFailed={(val) => { console.log(val) }}>
            <Form.Item name='phonenumber' label="手机号"
                rules={[
                    {
                        required: true,
                        message: '请输入手机号!',
                    },
                    {
                        pattern: /^1[3456789]\d{9}$/,
                        message: '请输入正确的手机号!'
                    }
                ]}
                hasFeedback
            >
                <Input />
            </Form.Item>
            <Form.Item name='email' label="邮箱"
                rules={[
                    {
                        required: true,
                        message: '请输入邮箱!',
                    }
                ]}
                hasFeedback
            >
                <Input />
            </Form.Item>
            <Form.Item style={{ textAlign: 'center' }}>
                <Button style={{ marginRight: '30px' }} onClick={() => { modalform.resetFields() }}>重置</Button>
                <Button type="primary" htmlType="submit">确认</Button>
            </Form.Item>
        </Form>
    )
}

export default React.memo(UserInfoModal)
