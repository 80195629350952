import React, { useState, Suspense } from "react";
import "./index.scss";
import css from "./index.module.scss";

import { Layout, Menu, Spin, Avatar,Upload, Breadcrumb, Modal, Button,Tooltip ,message,Image,Popover} from "antd";
import {
  UserOutlined,
  LockOutlined,
  LogoutOutlined,
  SettingOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import { Outlet, useNavigate } from "react-router-dom";
import router from "../../router";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteListItem,
  deleteListItemWithIgnore,
} from "../../store/features/historySlice";
import { updateAvatar } from "../../store/features/userInfoSlice";
import PwdModal from './pwdmodal';
import UserInfoModal from "./UserInfoModal";
import { uploadFile,updateUserInfo } from '../../api';
import { imgplaceholder } from "../../utils";
// import Userinfo from './header'
// import {getToken} from '../../utils/auth'

const { Content, Sider,Footer } = Layout;
// const { SubMenu } = Menu;
let checkSub = [],
  checkMenu = [],
  tempMenu = [],
  checktitle = [],
  temptitle = [];

function searchMenu(menu = { children: [] }, isFirst = false) {
  if (isFirst) {
    tempMenu = [];
    temptitle = [];
  }
  let thebool = false;
  for (let i = 0; i < menu.children.length; i++) {
    let item = menu.children[i];
    if (item.children) {
      let bool = searchMenu(item);
      thebool = bool;
      if (thebool) {
        tempMenu = [...tempMenu, item.id];
        temptitle = [...temptitle, item.title];
        checkMenu = tempMenu;
        checktitle = temptitle;
        return thebool;
      }
    } else {
      if (item.path === window.location.pathname) {
        checkSub = [item.id];
        tempMenu = [...tempMenu, item.id];
        temptitle = [...temptitle, item.title];
        checkMenu = tempMenu;
        checktitle = temptitle;
        return true;
      }
    }
  }
  return thebool;
}

const PopContent = React.memo(({ imgurl }) => {

  return (
      <div>
          <Image
              width={120}
              height={120}
              src={imgurl}
              fallback={imgplaceholder}
          />
      </div>

  )
})

export default function AppLayout(props) {
  const permList = useSelector(state => state.userInfo.permList);
  searchMenu(router[0], true);
  // const [collapsed,setCollapsed]=useState(false);
  const [title, setTitle] = useState(checktitle.reverse());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [selected, setSelected] = useState(checkMenu);
  // const [routes,setRoutes]=useState([]);
  const MenuList = createMenu(router[0].children);
  const routeList = getMenuList(MenuList);
  const navigate = useNavigate();

  const history = useSelector((state) => state.history.historyList);
  const userInfos = useSelector(state => state.userInfo.user);
  const dispatch = useDispatch();

  // function onCollapse(collapsed){
  //   setCollapsed(collapsed=>!collapsed);
  // };
  // async function getRoutes(){
  //   const result=await api.getRouter();
  //   setRoutes(JSON.parse(result.data));
  // }

  // useEffect(() => {
  //   dispatch(getInfoByToken());
  // }, []);

  function change(val) {
    const pos = routeList.findIndex((el) => el.key === val.key);
    if (pos > -1) {
      // dispatch(add({key:routeList[pos].path,value:undefined}));
      navigate(routeList[pos].path);
    }
    searchMenu(router[0], true);
    setTitle((title) => checktitle.reverse());
    setSelected(checkMenu);
  }
  function createMenu(menu = []) {
    let list = [];
    menu.forEach((item) => {
      // if (
      //   userPerms.findIndex((el) => Number(el.menuId) === Number(item.id)) < 0
      // ) {
      //   return;
      // }
      if (!permList.includes(item.id) && item.auth !== true) {
        return;
      }
      if (item.children) {
        list.push({
          key: item.id,
          label: item.title,
          icon: item.icon,
          path: item.path,
          visible: 1,
          children: createMenu(item.children),
        });
      } else {
        list.push({
          key: item.id,
          label: item.title,
          icon: item.icon,
          path: item.path,
          visible: 1,
        });
      }
    });
    return list;
  }
  function getMenuList(menu) {
    let list = [];
    menu.forEach((item) => {
      if (item.children) {
        const temp = getMenuList(item.children);
        list = [...list, ...temp, item];
      } else {
        list.push(item);
      }
    });
    return list;
  }
  function onOpenChange(keys) {
    setSelected(keys);
  }
  function closeHistory(e, path) {
    const index = history.findIndex((e) => e.key === path);
    if (path !== window.location.pathname) {
      dispatch(deleteListItem({ key: path, index }));
      return;
    }
    let target = "/";
    target = history[index + 1]?.key
      ? history[index + 1]?.key
      : history[0]?.key
        ? history[0]?.key
        : "/";
    dispatch(deleteListItemWithIgnore({ key: path, index }));
    navigate(target, { state: { mode: 1 } });
    searchMenu(router[0], true);
    setTitle((title) => checktitle.reverse());
    setSelected(checkMenu);
  }
  function goTo(path) {
    if (window.location.pathname === path) {
      return;
    }
    navigate(path, { state: { mode: 1 } });
    searchMenu(router[0], true);
    setTitle((title) => checktitle.reverse());
    setSelected(checkMenu);
  }
  const logout = () => {
    localStorage.removeItem("userToken");
    // navigate("/login", { replace: true });
    window.history.replaceState(null, null, "/login");
    window.location.reload();

  };
  const beforeUpload = async (file) => {
    try{
      if (file.size > 1024 * 1024 * 10) {
          message.error('文件大小不能超过10M');
          return false;
      }
      const formData = new FormData();
      formData.append('file', file);
      const res = await uploadFile(formData);
      if (res.code === 200) {
          dispatch(updateAvatar(res.data));
          const res1 = await updateUserInfo({ avatar: res.data });
          if (res1.code === 200) {
              message.success({ content: '头像修改成功', duration: 2 });
              return true;
          }else {
              throw new Error(res1.message??'上传失败');
          }
      } else {
          throw new Error(res.message??'上传失败');
      }
    }catch(e){
      message.error({ content: '上传失败', duration: 2 });
    }finally{
      return false;
    }
}

  return (
    <div style={{ width: "100%", height: "100%", background: "#e6e6e6" }}>
      <Layout className={css.botlayout}>
        <Sider
          theme="light"
          className={css.sider}
          collapsed={collapsed}
        >
          <div className={!collapsed ?css.logobox:css.logoboxv}>
            <div className={css.flex}>
              <div className={css.logo}></div>
              {!collapsed ?<div className={css.qsstitle}>轻松收</div>:null}
            </div>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                color: '#888'
              }}
            />
          </div>
          <Menu
            theme="light"
            style={{ background: "none" }}
            selectedKeys={checkSub}
            onOpenChange={onOpenChange}
            openKeys={selected}
            mode="inline"
            onClick={change}
            items={MenuList}
          ></Menu>
        </Sider>
        <Content className={css.content}>
          <div className={`${css.header}`}>
            <div className={css.welcome}>欢迎，{userInfos ? userInfos.userName : '用户'}</div>
            <div className={css.userbox}>
              <Popover zIndex={10} content={<PopContent imgurl='/uploadFile/imgs/20241227101615623.png' />} title="微信小程序码" trigger="hover">
                <WechatOutlined className={css.item}/>
              </Popover>
              <Tooltip title="修改信息" className={css.item}>
                <SettingOutlined onClick={()=>setUserModalVisible(true)}/>
              </Tooltip>
              <Tooltip title="修改密码" className={css.item}>
                <LockOutlined onClick={()=>setIsModalVisible(true)}/>
              </Tooltip>
              <Tooltip title="退出登录" className={css.item}>
                <LogoutOutlined onClick={logout}/>
              </Tooltip>
              <Upload
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                <Avatar size={40} icon={<UserOutlined />} src={userInfos?userInfos.avatar:''} shape="circle"/>
              </Upload>
            </div>
          </div>
          <div style={{ height: 40, display: "flex", alignItems: "center", }}>
            {/* <div style={{ flex: "1" }}>
              {history.map((e) => {
                return (
                  <Tag
                    key={e.key}
                    className={`${css.Tag} ${e.key === window.location.pathname ? css.TagSelected : ""
                      }`}
                    closable={history.length > 1}
                    onClose={(event) => {
                      closeHistory(event, e.key);
                    }}
                    onClick={() => {
                      goTo(e.key);
                    }}
                    closeIcon={<CloseOutlined className={css.closeicon} />}
                  >
                    {e.name}
                  </Tag>
                );
              })}
            </div> */}
            <div style={{ flex: "1" }}></div>
            <Breadcrumb
              style={{ margin: "0 10px", fontSize: "12px" }}
              separator=">"
            >
              {title.map((e, i) => {
                return <Breadcrumb.Item key={i}>{e}</Breadcrumb.Item>;
              })}
            </Breadcrumb>
          </div>
          <div
            className="site-layout-background"
            style={{ padding: '0 0 40px', flex: "1", overflowY: "auto" ,minHeight:'calc(100vh - 180px)'}}
          >
            <Suspense
              fallback={
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    marginTop: "100px",
                  }}
                >
                  <Spin size="large" />
                </div>
              }
            >
              <Outlet />
            </Suspense>
          </div>
          <Footer
            className={css.footer}
          >
            <span className={css.footer_title}>轻松收</span> ©{new Date().getFullYear()} <span className={css.company}>杭州新利科技有限公司</span><span className={css.footer_tails}>版权所有</span>
          </Footer>
        </Content>

        <Modal title="修改密码" open={isModalVisible} onCancel={() => { setIsModalVisible(false) }}
          bodyStyle={{ maxHeight: '700px', overflowY: 'auto' }}
          footer={null}
          style={{ top: '50px' }}
          destroyOnClose={true}
        >
          <PwdModal close={() => { setIsModalVisible(false); logout(); }}></PwdModal>
        </Modal>

        <Modal title="修改信息" open={userModalVisible} onCancel={() => { setUserModalVisible(false) }}
          bodyStyle={{ maxHeight: '700px', overflowY: 'auto' }}
          footer={null}
          style={{ top: '50px' }}
          destroyOnClose={true}
        >
          <UserInfoModal close={() => setUserModalVisible(false) }></UserInfoModal>
        </Modal>
      </Layout>
    </div>
  );
}
