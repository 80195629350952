import index from "./index.module.scss";

export const NewTableTh =  {
  header:{
    wrapper:(props)=> (
        <thead className={index.newTableth}>{props.children}</thead>
    )
  }
}

export const NewTableThR =  {
  header:{
    wrapper:(props)=> (
        <thead className={index.newTablethR}>{props.children}</thead>
    )
  }
}