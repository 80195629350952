import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserInfo } from '../../api';
import { getToken } from '../../utils/auth';

const initialState = {
    permList: [],
    user: null,
};

export const getInfoByToken = createAsyncThunk('userInfo/userInfoObj', async () => {
    const token = getToken();
    if (!token) return Promise.reject();
    let result = await getUserInfo();
    return result;
})

export const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        updateAvatar(state, { payload }) {
            state.user.avatar = payload;
        },
        updateUser(state, { payload }) {
            state.user.phonenumber = payload.phonenumber;
            state.user.email = payload.email;
        },
    },
    extraReducers: {
        [getInfoByToken.fulfilled](state, { payload }) {
            if (payload.code === 200) {
                const set = new Set();
                for (let i of payload.data.menuList) {
                    set.add(i.id + '');
                }
                const halfIds = payload.data.halfIds.reduce((prev, cur) => {
                    if (cur) prev.push(...cur.split(','));
                    return prev;
                },
                    []);
                for (let i of halfIds) {
                    set.add(i);
                }
                state.permList = [...set];
                state.user = payload.data.userinfo;
            }
        },
    }
})

export const { updateAvatar,updateUser } = userInfoSlice.actions;

export default userInfoSlice.reducer;